body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.my-nav-link.active {
  background-color: red; /* Replace with your desired color */
  color: red; /* Optionally, change text color */
}

.hover-red:hover {
  color: red !important ;
  transition: 0.2s;
  cursor: pointer;
}

.react-tagsinput--focuseds {
  background-color: #2ab57d;
  margin-right: 2px;
  padding: 4px;
  border-radius: 5px;
  color: white;
}

.myCustomGridStyles {
  --ag-font-size: 0.6rem !important;
  --ag-row-hover-color: #d2e9dcc0 !important;
  --ag-borders: 0px !important;
  --ag-header-background-color: #c9dbdd20 !important;  
}
.myCustomGridStyles .ag-root-wrapper{
  border-radius:  0 !important;
}
.myCustomGridStyles .ag-header-cell-text {
  font-size: 0.8rem !important;
}
.myCustomGridStyles .ag-row {
  line-height: 0.6 !important;
  padding: 5px 0;
}
.myCustomGridStyles .ag-cell-value {
  line-height: 10px !important;
  padding: 5px 0;
  height: 22px !important;
}
.ag-cell-wrapper.ag-row-group {
  margin-top: -12px !important;
}
.myCustomGridStyles .ag-header {
  border-bottom: 2px solid #67bbc5 !important; /* Adjust border color and thickness as needed */
}
